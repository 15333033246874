/** @jsx jsx */
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { jsx, css } from '@emotion/core'

const SelectWrapper = styled.div`
  position: relative;
`

const SelectField = styled.select`
  ${tw`
    font-montserrat
    block
    appearance-none
    w-full
    bg-transparent
    border
    border-border-blue
    px-4 py-3 pr-10
    rounded-full
    leading-none
    tracking-tight
    tracking-wide
    text-grey-darker
    focus:outline-none
    focus:shadow-outline
  `}
`

const SelectArrow = styled.div`
  ${tw`
    pointer-events-none
    absolute
    pin-y pin-r
    flex
    items-center
    px-3
    text-grey-darker
  `}
`

const Select = (props) => {
  const { children } = props;
  return (
    <SelectWrapper>
      <SelectField {...props}>
        {children}
      </SelectField>
      <SelectArrow>
        <svg
          css={css`
            ${tw`fill-current text-border-blue h-5 w-5`}
          `}
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </SelectArrow>
    </SelectWrapper>
  )
}

Select.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.element
  ]).isRequired
}

Select.defaultProps = {}

export default Select
