import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { DebounceInput } from 'react-debounce-input';

const SearchIcon = () => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
    <g id="Designs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="14-Home-Solutions" transform="translate(-222.000000, -745.000000)" fill="#4D8DCD" fillRule="nonzero">
        <g id="Group-Copy" transform="translate(200.000000, 729.000000)">
          <g id="zoom-split" transform="translate(22.000000, 16.000000)">
            <path d="M7,14 C3.14,14 0,10.86 0,7 C0,3.14 3.14,0 7,0 C10.86,0 14,3.14 14,7 C14,10.86 10.86,14 7,14 Z M7,2 C4.243,2 2,4.243 2,7 C2,9.757 4.243,12 7,12 C9.757,12 12,9.757 12,7 C12,4.243 9.757,2 7,2 Z" id="Shape" />
            <path d="M15.707,14.293 L13.314,11.9 C12.903,12.429 12.429,12.903 11.9,13.314 L14.293,15.707 C14.488,15.902 14.744,16 15,16 C15.256,16 15.512,15.902 15.707,15.707 C16.098,15.316 16.098,14.684 15.707,14.293 Z" id="Shape" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const DefaultStyling = css`
  ${tw`
    bg-transparent
    font-montserrat
    appearance-none
    border
    border-border-blue
    rounded-full
    w-full
    py-3 px-4
    text-base
    text-grey-darker
    leading-none
    tracking-wide
    focus:outline-none
    focus:shadow-outline
    appearance-none
  `}
`

const InputField = styled.input`
  ${DefaultStyling}
`

const DebounceInputField = styled(DebounceInput)`
  ${DefaultStyling}
`

const IconWrapper = styled.div`
  ${tw`absolute`}
`

const InputIconWrapper = styled.div`
  ${tw`
    relative
  `}

  ${IconWrapper} {
    left: 15px;
    top: 14px;
    ${tw`w-4 h-4`}
  }

  ${InputField},
  ${DebounceInputField} {
    ${tw`pl-10`}
  }
`

const icons = {
  search: SearchIcon
}

const Input = (props) => {
  const { icon, debounce } = props
  const Icon = icons[icon];

  let Element = null
  if (debounce) {
    Element = DebounceInputField
  } else {
    Element = InputField
  }

  return (
    <React.Fragment>
      {icon
        ?
        (
          <InputIconWrapper>
            <Element {...props} />
            <IconWrapper>
              <Icon />
            </IconWrapper>
          </InputIconWrapper>
        )
        :
          <Element {...props} />
      }
    </React.Fragment>
  )
}

Input.propTypes = {
  // Icon
  icon: PropTypes.string,
  debounce: PropTypes.bool
}

Input.defaultProps = {
  icon: '',
  debounce: false
}

export default Input
