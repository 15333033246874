import React from 'react'
import styled from '@emotion/styled'
import Prismic from 'prismic-javascript';
import { RichText } from 'prismic-reactjs';
import {injectIntl} from 'react-intl';
import 'url-search-params-polyfill';
import Masonry from 'react-masonry-css'

import ContentLoader from "react-content-loader"
import Imgix from "react-imgix";
import { Link as GatsbyLink } from "gatsby"

import { Flex, Box } from '../../common/Grid'
import Header from '../../theme/Header'
import ImageHeader from '../../theme/ImageHeader'
import Select from '../../common/Select'
import Input from '../../common/Input'
import Button from '../../common/Button'

import sendEvent from '../../../helpers/sendAnalytics'

const BlogPostsWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1040px;

  a {
    text-decoration: none;
  }
`

const BlogPostWrapper = styled.div`
  display: inline-block;
  margin: 0 0 60px;
  width: 100%;
`

const SearchWrapper = styled.div`
  ${ tw`bg-light-blue`}
  margin: -6rem 0px -100px;
  padding: 116px 0px 142px;

  @media screen and (max-width: 64em) {
    margin: 20px 0px 20px 0px;
    padding: 20px 1.25rem;
  }
`

const SearchContainer = styled.div`
  ${tw`mx-auto`}
  max-width: 1040px;
`

const BlogPostHeader = styled.h2`
  ${tw`font-montserrat font-normal text-2xl text-black m-0 my-6`}
  margin-left: 20px;

	h1 {
		${tw`font-montserrat font-normal text-2xl text-black m-0 my-6`}
	}
`

const BlogPostBody = styled.div`
  ${tw`font-raleway text-lg text-blog-text leading-tight`}
  margin-left: 20px;

  p {
    margin: 0px;
  }
`

const CentreButton = styled.div`
  ${tw`text-center`}
`

const BlogPost = ({ title, to, image, description }) => (
  <BlogPostWrapper>
    <GatsbyLink to={to}>
      <Imgix src={image} width={505} alt={title} />
      <BlogPostHeader>
        {RichText.render(title)}
      </BlogPostHeader>
      <BlogPostBody>{RichText.render(description)}</BlogPostBody>
    </GatsbyLink>
  </BlogPostWrapper>
)

const Loading = ({ height }) => (
  <BlogPostWrapper>
    <ContentLoader
      height={height}
      width={510}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="22" y={height - 50} rx="4" ry="4" width="250" height="13" />
      <rect x="22" y={height - 25} rx="4" ry="4" width="150" height="8" />
      <rect x="0" y="0" rx="5" ry="5" width="510" height={height - 80} />
    </ContentLoader>
  </BlogPostWrapper>
)

class BlogListing extends React.Component {
  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(
      props.location.search
    );

    this.state = {
      loading: true,
      blogPosts: [],
      blogCategories: [],
      blogCategory: urlParams.get('category'),
      search: '',
      page: 1,
      totalPages: 0
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  componentDidMount() {
    this.searchPosts(true);

    const { locale } = this.props
    const { blogCategory } = this.state

    Prismic.api(process.env.PRISMIC_API, {
      accessToken: process.env.PRISMIC_TOKEN
    }).then(api => {
      api.query(
        Prismic.Predicates.at(
          'document.type', 'home_solution_category'
        ),
        {lang:(locale === 'en') ? 'en-ca' : 'fr-ca'}
      ).then(response => {
        if (response) {
          this.setState({
            blogCategories: response.results
          });
        }
      });
    });

    if (blogCategory && typeof window !== "undefined") {
      document.getElementById('blog-posts').scrollIntoView()
    }
  }

  searchPosts(clear) {
    const {
      search,
      blogCategory,
      page,
      blogPosts
    } = this.state;
    const { locale } = this.props

    const query = [
      Prismic.Predicates.at(
        'document.type',
        'home_solution_article'
      )
    ];

    if (typeof window !== "undefined" && window.location.hostname === 'www.royale.ca') {
      query.push(
        Prismic.Predicates.not(
          'my.home_solution_article.hidden_from_the_listing',
          'Yes'
        )
      )
    }

    if (search) {
      query.push(
        Prismic.Predicates.fulltext(
          'document', search,
        )
      )
    }

    if (blogCategory) {
      query.push(
        Prismic.Predicates.at(
          'my.home_solution_article.category',
          blogCategory
        )
      )
    }

    Prismic.api(process.env.PRISMIC_API, {
      accessToken: process.env.PRISMIC_TOKEN
    }).then(api => {
      api.query(
        query,
        { pageSize: 6, page, lang:(locale === 'en') ? 'en-ca' : 'fr-ca', orderings: '[document.first_publication_date desc]' }
      ).then(response => {
        if (response) {
          if (clear) {
            this.setState({
              blogPosts: response.results,
              loading: false,
              totalPages: response.total_pages
            });
          } else {
            this.setState({
              blogPosts: blogPosts.concat(response.results)
            });
          }
        }
      });
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.searchPosts(true);
  }

  handleChange(e) {
    const { locale } = this.props

    const change = {}
    change[e.target.name] = e.target.value
    this.setState(change, () => {
      this.searchPosts(true);
    })

    if (e.target.name === 'blogCategory') {
      let blogEvent = '';
      switch (e.target.value) {
        // Food Prep
        case 'W9dM3BIAACsAXeYt':
          blogEvent = 'Food_Prep';
          break;
        // BBQ
        case 'W9dChBIAACoAXba0':
          blogEvent = 'BBQ';
          break;
        // De-cluttering
        case 'W9dXvRIAACsAXhex':
          blogEvent = 'De_Cluttering';
          break;
        // Spring Cleaning
        case 'W9ymXBIAACsAddqk':
          blogEvent = 'Spring_Cleaning';
          break;
        // Outdoors
        case 'W9dTxRIAACwAXgV8':
          blogEvent = 'Outdoors';
          break;
        // Pet Care
        case 'W-OM_xIAACkAAzcq':
          blogEvent = 'Pet_Care';
          break;
        // Holidays
        case 'W-ONKxIAACEAAzfw':
          blogEvent = 'Holidays';
          break;
        // Surprising Solutions
        case 'W-TN7REAACYADSir':
          blogEvent = 'Surprising_Solutions';
          break;
        default:
          blogEvent = e.target.value;
      }

      sendEvent({
        ga: {
          category: 'Home_Solutions',
          action: 'Theme_Filter',
          label: (locale === 'en') ? blogEvent : `${blogEvent}_FR`
        }
      })
    }
  }

  handleLoadMore() {
    const { page } = this.state
    this.setState({
      page: page + 1
    }, () => {
      this.searchPosts(false);
    })
  }

  render() {
    const loadingMap = [318, 385, 426, 377, 359, 450];
    const {
      loading,
      blogPosts,
      blogCategories,
      search,
      blogCategory,
      page,
      totalPages
    } = this.state;
    const { title, image, alt, subtitle, urls, locale, intl } = this.props
    return (
      <React.Fragment>
        <Header
          urls={urls}
          locale={locale}
        />

        <ImageHeader
          title={title}
          subtitle={subtitle}
          image={image}
          alt={alt}
          house
        />

        <SearchWrapper id="blog-posts">

          <SearchContainer>
            <form onSubmit={this.handleSubmit}>
              <Flex justifyContent="space-between" flexWrap="wrap">
                <Box width={['100%', '100%', '100%', 'auto']} mb={[3, 3, 3, 0]}>
                  <Input
                    type="text"
                    name="search"
                    onChange={this.handleChange}
                    value={search}
                    placeholder={intl.formatMessage({id:'search_posts'})}
                    icon="search"
                    debounce
                    debounceTimeout={300}
                  />
                </Box>

                <Box width={['100%', '100%', '100%', 'auto']}>
                  <Select
                    name="blogCategory"
                    onChange={this.handleChange}
                    value={blogCategory}
                  >
                    <option value="">{intl.formatMessage({id:'themes'})}</option>
                    {blogCategories.map(category => (
                      <option value={category.id}>{category.data.name[0].text}</option>
                    ))}
                  </Select>
                </Box>
              </Flex>
            </form>
          </SearchContainer>
        </SearchWrapper>

        <BlogPostsWrapper>
          <Masonry
            breakpointCols={2}
            className="blog-masonry-grid"
            columnClassName="blog-grid_column"
          >
            {
              (loading)
                ?
                loadingMap.map((height) =>
                  <Loading height={height} key={height} />
                )
                :
                blogPosts.map((post) => (
                  <BlogPost
                    key={post.id}
                    title={post.data.title}
                    image={post.data.preview_image.url}
                    to={(locale === 'en') ? `/home-solutions/${post.slugs[0]}/` : `/fr/solutions-maison/${post.slugs[0]}/`}
                    description={post.data.preview_description}
                  />
                ))
            }
          </Masonry>
        </BlogPostsWrapper>

        <CentreButton>
          { (totalPages > page) ?
            (
              <Button
                type="button"
                outline
                onClick={this.handleLoadMore}
              >
                {intl.formatMessage({id:'load_more'})}
              </Button>
            )
          : null }
        </CentreButton>
      </React.Fragment>
    )
  }
}

export default injectIntl(BlogListing)
